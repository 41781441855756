import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Client } from 'src/app/models/client';
import { Company } from 'src/app/models/company';
import { DebitNote } from 'src/app/models/debit-note';
import { InvoiceDetail } from 'src/app/models/invoiceDetail';
import { InvoiceHeader } from 'src/app/models/invoiceHeader';
import { Store } from 'src/app/models/store';
import { ClientService } from 'src/app/services/client.service';
import { CompanyService } from 'src/app/services/company.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { StoreService } from 'src/app/services/store.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-inventory-debit-note',
  templateUrl: './inventory-debit-note.component.html',
  styleUrls: ['./inventory-debit-note.component.scss'],
  providers: [UtilsService, ClientService, InventoryService, InvoiceService, StoreService, CompanyService]
})
export class InventoryDebitNoteComponent implements OnInit {
  // Variables auxiliares  
  store = {} as Store;
  client = {} as Client;
  company = {} as Company;
  debitNote = {} as DebitNote;
  invoiceHeader = {} as InvoiceHeader;
  invoiceDetail = {} as InvoiceDetail;
  public begDate: string;
  public endDate: string;
  public nowDay: string;
  public userCode: number;
  public storeCode: number;
  public companyCode: number;
  loading;

  public isBeginning: boolean;
  public isDebitNote: boolean;
  public isEdit: boolean;
  public isDisabled: boolean;

  // variables para data table
  public test: any = `<button (click)="onSelect($event)">Click me</button>`;
  public entries: number = 10;
  public selected: any[] = [];
  public activeRow: any;
  public temp: any[] = new Array();
  public invoiceList: any[] = new Array();

  // DATA SELECT
  public id: number;
  public itemName: string;
  public selectedItems = [];
  public selectedItemsSerial = [];
  public inventoryList: any[] = new Array();
  public serialList: any[] = new Array();
  public dropdownSettings: IDropdownSettings = {};
  public dropdownSettingsSerial: IDropdownSettings = {};
  closeDropdownSelection = false;
  closeDropdownSelectionSerial = false;
  public serialSelected = new Array;
  public serialSelectedTemp = new Array;

  // Variables de Linea de Compra
  public itemCode: string;
  public stock: number;
  public moneda: string;
  public invoiceLine: any = new Array;
  public addAmount: boolean;
  public priceType: number;

  constructor(
    private toastr: ToastrService,
    private cdref: ChangeDetectorRef,
    private _utilsService: UtilsService,
    private _invoiceService: InvoiceService,
    private _companyService: CompanyService,
    private _storeService: StoreService,
    private _inventoryService: InventoryService
  ) { }

  async ngOnInit() {
    this.addAmount = true;
    this.isEdit = false;
    this.isDisabled = false;
    this.moneda = "Q";
    this.priceType = 1;
    this.invoiceHeader.invoiceCoin = 0;
    this.invoiceHeader.invoiceTax = 0;
    this.invoiceHeader.invoiceTotal = 0;
    this.invoiceDetail.invoiceDetailType = 'B';
    this.invoiceHeader.invoiceInternSerial = 'A';

    // DATE
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    this.nowDay = yyyy + '-' + mm + '-' + dd;
    this.begDate = this.nowDay;
    this.endDate = this.nowDay;

    this.invoiceHeader.invoiceDate = this.nowDay;

    // GET ID USER & ID COMPANY
    let _data;
    if (localStorage.getItem('data')) {
      _data = JSON.parse(localStorage.getItem('data'));
    }
    this.companyCode = _data.companyCode ? parseInt(_data.companyCode.toString()) : 0;
    this.storeCode = _data.storeCode ? parseInt(_data.storeCode.toString()) : 0;
    this.userCode = _data.userCode ? parseInt(_data.userCode.toString()) : 0;

    this.viewBeginning();
    await this.getStore(this.storeCode);
    await this.getCompany(this.companyCode);
    await this.getInventoryList(this.storeCode);
    this.getSelectSetting(this.selectedItems);
    this.getSelectSettingSerial(this.selectedItemsSerial);
  }

  public taxToPay: number = 0;
  ///////////////////////COMPANY///////////////////////
  getCompany(company) {
    return this._companyService.getOne(company).then(
      response => {
        if (response.result) {
          this.company = response.records[0];
          this.company.companyTaxToPay == 12 ? this.taxToPay = 1.12 : this.taxToPay = 1.05;
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  ///////////////////////STORE///////////////////////
  getStore(store) {
    return this._storeService.getOne(store).then(
      response => {
        if (response.result) {
          this.store = response.records[0];
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    );
  }

  ///////////////////////FUNCIONES PARA TABLA///////////////////////
  entriesChange($event) {
    this.entries = $event.target.value;
  }

  filterTable($event) {
    let val = $event.target.value.toLowerCase(); 
    this.temp = this.invoiceList.filter(function (d) {

      for (var key in d) {
        if (d[key] !== null) {
          if (d[key].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
      return false;
    });
  }

  onActivate(event) {
    this.activeRow = event.row;
  }

  ///////////////////////SELECT///////////////////////
  getSelectSetting(selected) {
    Object.keys(selected).length > 0 ? this.selectedItems = [selected] : '';

    this.dropdownSettings = {
      singleSelection: true,
      searchPlaceholderText: 'Buscar...',
      closeDropDownOnSelection: true,
      allowSearchFilter: true,
      idField: 'id',
      textField: 'itemName',
    };
  }

  getSelectSettingSerial(selected) {
    Object.keys(selected).length > 0 ? this.selectedItemsSerial = [selected] : '';

    this.dropdownSettingsSerial = {
      idField: 'InventorySerialCode',
      textField: 'inventorySerialSerial',
      itemsShowLimit: 3,
      enableCheckAll: false,
      singleSelection: false,
      allowSearchFilter: true,
      searchPlaceholderText: "Buscar",
    }
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  toggleCloseDropdownSelection() {
    this.closeDropdownSelection = !this.closeDropdownSelection;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { closeDropDownOnSelection: this.closeDropdownSelection });
  }

  getInventoryList(company) {
    return this._inventoryService.getAllByStore(company).then(
      response => {
        if (response.result) {
          // console.log(response.records);
          this.inventoryList = response.records;
          this.inventoryList.forEach((e) => {
            e.itemName = e.itemName.replace(/&apos;/g, '\'');
          });
        } else {
          console.log(response.message);
        }
      },
      error => {
        console.log(error);
      }
    ).finally(() => {
      this.loading = false;
    });
  }

  serial = false;
  async onItemSelect(item: any) {
    const _selectedItem = await this.inventoryList.find(x => x.id == item.id);

    this.itemCode = item.id;
    this.invoiceDetail.invoiceDetailDescription = item.itemName;
    this.invoiceDetail.invoiceDetailType = 'B';
    this.stock = _selectedItem.inventoryQuantity;

    this.itemCode = item.id;
    this.invoiceDetail.invoiceDetailDescription = item.itemName;
    this.invoiceDetail.invoiceDetailType = 'B';
    this.stock = _selectedItem.inventoryQuantity;

    if (_selectedItem.inventorySerialFlag == 1) {
      this.loading = true;
      this.serialSelectedTemp = [];
      this.serialList = [];
      const response = await this._inventoryService.getItemSerial(this.itemCode);
      this.serialList = response.records[1];

      this.serial = true;
      this.loading = false;
    } else {
      this.serial = false
    }
  }

  onItemSelectSerial(item: any) {
    this.serialSelected.push({
      serialCode: item.InventorySerialCode,
      inventorySerialSerial: item.inventorySerialSerial
    });

    this.serialSelectedTemp.push({
      serialCode: item.InventorySerialCode,
      inventorySerialSerial: item.inventorySerialSerial
    });
  }

  async onItemDeSelectSerial(item: any) {
    this.serialSelected.forEach((e, index) => {
      if (e.serialCode == item.InventorySerialCode)
        this.serialSelected.splice(index, 1);
    });

    this.serialSelectedTemp.forEach((i, indece) => {
      if (i.serialCode == item.InventorySerialCode)
        this.serialSelectedTemp.splice(indece, 1);
    });
  }

  ///////////////////////CHANGE DATE///////////////////////
  changeDate($event) {
    $event.preventDefault();
    this.endDate = this.begDate;
  }

  ///////////////////////FUNCTION CHANGE PRICE///////////////////////
  onChangePrice(event) {
    this.priceType = parseFloat(event);
  }

  ///////////////////////CRUD///////////////////////
  getAll() {
    this.isDisabled = true;
    if (this.begDate && this.endDate) {
      this.loading = true;

      let json = {
        "storeCode": this.storeCode,
        "begDate": this.begDate,
        "endDate": this.endDate
      }

      this._invoiceService.getAllByCompany(json).then(
        async (response) => {
          if (response.result == true) {
            this.invoiceList = response.records;
            this.temp = this.invoiceList;
            this.isDisabled = false;
            this.showNotification(1, response.message);
          } else {
            this.isDisabled = false;
            this.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this.isDisabled = false;
          this.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.loading = false;
      });
    } else {
      this.isDisabled = false;
      this.showNotification(2, "Ingresar Datos");
    }
  }

  // GET ONE
  searchInvoice($event, code?) {
    $event.preventDefault();
    this.loading = true;

    //INFILE
    if (this.company.companyCertifier == 0) {
      let uuid = this.activeRow.invoiceAuthorization;
      window.open('https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=' + uuid, 'popup', 'width=600,height=600');
    }

    //MEGAPRINT
    else if (this.company.companyCertifier == 1) {

      this._invoiceService.getOneMegaprint(this.activeRow.id).then(
        async (response) => {
          if (response.result == true) {
            var pdfResult = response.records.pdfBase64;
            let pdfWindow = window.open("")
            pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(pdfResult) + "'></iframe>");
          } else {
            this.showNotification(3, response.errorMessage);
          }
        },
        error => {
          this.showNotification(2, error);
          console.log(error);
        }
      ).finally(() => {
        this.isDisabled = false;
        this.loading = false;
      });
    }

  }

  createDebitNote($event) {
    $event.preventDefault();
    this.loading = true;

    //SIMULACION DE TIEMPO DE CARGA DE NOTA DEBITO
    setTimeout(() => {
      this.loading = false;
      this.viewDebitNote();
    }, 1500);

    this.invoiceHeader.invoiceCode = this.activeRow.id;
    this.client.clientCode = this.activeRow.clientCode;
    this.client.clientName = this.activeRow.cliente;
    this.client.clientTaxDocument = this.activeRow.nit;
    this.client.clientAddress = this.activeRow.clientAddress;
    this.client.clientPhone = this.activeRow.clientPhone;
    this.client.clientEmail = this.activeRow.clientEmail;
    this.debitNote.debitNoteDate = this.activeRow.fecha;
    this.debitNote.debitNoteDate = this._utilsService.dateES_US(this.activeRow.fecha, '-');
    // this.creditNote.creditNoteDate = this._utilsService.dateES_US(this.activeRow.fecha, '-');
    this.debitNote.debitNoteDocument = this.activeRow.invoiceAuthorization;

    // this.invoiceHeader.invoiceCode = this.activeRow.id;
  }

  // GUARDAR UNA LINEA DE COMPRA
  async saveLineInvoice() {

    //Actualizar?
    let update = false;
    await this.invoiceLine.forEach(async (e) => {

      if (e.itemCode == this.itemCode) {
        //Validacion
        let quantity;
        this.isEdit === true ? quantity = this.invoiceDetail.invoiceDetailQuantity : quantity = e.quantity + this.invoiceDetail.invoiceDetailQuantity;

        const response = await this._inventoryService.getPriceByItem(this.itemCode, quantity);
        switch (this.priceType) {
          case 1: this.invoiceDetail.invoiceDetailUnitPrice = response.records.precio;
            break;
          case 2: this.invoiceDetail.invoiceDetailUnitPrice = response.records.inventoryPriceDiscount;
            break;
          case 3: this.invoiceDetail.invoiceDetailUnitPrice = response.records.inventorySalePrice;
            break;
          case 4: this.invoiceDetail.invoiceDetailUnitPrice = response.records.inventoryminprice;
            break;
        }

        if (this.company.companyExistenceFlag === 1 || quantity <= this.stock) {
          //Restar Encabezado
          this.invoiceHeader.invoiceTotal -= parseFloat((e.total).toFixed(2));
          this.invoiceHeader.invoiceTax -= parseFloat((e.taxAmount).toFixed(2));

          //Realizar Calculos
          if (parseFloat(e.discount) > 0) {
            this.invoiceDetail.invoiceDetailSubtotal = parseFloat((quantity * this.invoiceDetail.invoiceDetailUnitPrice).toFixed(2));
            this.invoiceDetail.invoiceDetailSubtotal = parseFloat((this.invoiceDetail.invoiceDetailSubtotal - e.discount).toFixed(2));
            this.invoiceDetail.invoiceDetailTax = parseFloat((this.invoiceDetail.invoiceDetailSubtotal - (this.invoiceDetail.invoiceDetailSubtotal / this.taxToPay)).toFixed(2));
          } else {
            this.invoiceDetail.invoiceDetailDiscount = e.discount;
            this.invoiceDetail.invoiceDetailSubtotal = quantity * this.invoiceDetail.invoiceDetailUnitPrice;
            this.invoiceDetail.invoiceDetailTax = parseFloat((this.invoiceDetail.invoiceDetailSubtotal - (this.invoiceDetail.invoiceDetailSubtotal / this.taxToPay)).toFixed(2));
          }

          //Editar Linea
          e.quantity = quantity;
          e.unitPrice = this.invoiceDetail.invoiceDetailUnitPrice;
          e.price = parseFloat((this.invoiceDetail.invoiceDetailSubtotal).toFixed(2));
          e.taxAmount = parseFloat((this.invoiceDetail.invoiceDetailTax).toFixed(2));
          e.taxableAmount = parseFloat((this.invoiceDetail.invoiceDetailSubtotal - this.invoiceDetail.invoiceDetailTax).toFixed(2));
          e.total = parseFloat((this.invoiceDetail.invoiceDetailSubtotal).toFixed(2));

          //Sumar Encabezado 
          this.invoiceHeader.invoiceTotal += parseFloat(this.invoiceDetail.invoiceDetailSubtotal.toFixed(2));
          this.invoiceHeader.invoiceTax += parseFloat(this.invoiceDetail.invoiceDetailTax.toFixed(2));
          this.cleanLineInvoice();
        } else {
          this.showNotification(2, 'Cantidad Excede Exitencia');
        }
        update = true;
        this.isEdit = false;
        this.addAmount = false;
        this.isDisabled = false;
      }
    });

    //Nuevo Item
    if (this.addAmount === true && update === false) {
      const response = await this._inventoryService.getPriceByItem(this.itemCode, this.invoiceDetail.invoiceDetailQuantity);
      switch (this.priceType) {
        case 1: this.invoiceDetail.invoiceDetailUnitPrice = response.records.precio;
          break;
        case 2: this.invoiceDetail.invoiceDetailUnitPrice = response.records.inventoryPriceDiscount;
          break;
        case 3: this.invoiceDetail.invoiceDetailUnitPrice = response.records.inventorySalePrice;
          break;
        case 4: this.invoiceDetail.invoiceDetailUnitPrice = response.records.inventoryminprice;
          break;
      }

      //Realizar Calculos
      this.invoiceDetail.invoiceDetailSubtotal = parseFloat((this.invoiceDetail.invoiceDetailQuantity * this.invoiceDetail.invoiceDetailUnitPrice).toFixed(2));
      this.invoiceDetail.invoiceDetailTax = parseFloat((this.invoiceDetail.invoiceDetailSubtotal - (this.invoiceDetail.invoiceDetailSubtotal / this.taxToPay)).toFixed(2));

      //Validacion
      if (this.company.companyExistenceFlag === 1 || this.invoiceDetail.invoiceDetailQuantity <= this.stock) {

        //Guardar Linea
        this.invoiceLine.push({
          itemCode: this.itemCode,
          measure: "UNI",
          taxCode: 1,
          taxName: "IVA",
          isService: this.invoiceDetail.invoiceDetailType,
          quantity: this.invoiceDetail.invoiceDetailQuantity,
          description: this.invoiceDetail.invoiceDetailDescription,
          discount: 0,
          unitPrice: parseFloat(this.invoiceDetail.invoiceDetailUnitPrice.toFixed(2)),
          price: parseFloat((this.invoiceDetail.invoiceDetailSubtotal).toFixed(2)),
          taxAmount: parseFloat(this.invoiceDetail.invoiceDetailTax.toFixed(2)),
          taxableAmount: parseFloat((this.invoiceDetail.invoiceDetailSubtotal - this.invoiceDetail.invoiceDetailTax).toFixed(2)),
          total: parseFloat(this.invoiceDetail.invoiceDetailSubtotal.toFixed(2)),
          // CodProducto: null
        });
        this.invoiceHeader.invoiceTotal += parseFloat(this.invoiceDetail.invoiceDetailSubtotal.toFixed(2));
        this.invoiceHeader.invoiceTax += parseFloat(this.invoiceDetail.invoiceDetailTax.toFixed(2));
        this.cleanLineInvoice();
      } else {
        this.showNotification(2, 'Cantidad Excede Exitencia');
      }
      this.isDisabled = false;
    }
  }

  // GUARDAR LINEA CON SERIES
  async saveLineInvoiceSerial() {
    this.isDisabled = true;
    let res_find = await this.invoiceLine.find(x => x.itemCode === this.itemCode);

    if (!res_find) {
      if (this.invoiceDetail.invoiceDetailQuantity == this.serialSelectedTemp.length) {

        this.invoiceDetail.invoiceDetailDescription += ' SERIE: ';
        for await (const e of this.serialSelectedTemp) {
          this.invoiceDetail.invoiceDetailDescription += e.inventorySerialSerial + ', ';
        }

        const response = await this._inventoryService.getPriceByItem(this.itemCode, this.invoiceDetail.invoiceDetailQuantity);
        switch (this.priceType) {
          case 1: this.invoiceDetail.invoiceDetailUnitPrice = response.records.precio;
            break;
          case 2: this.invoiceDetail.invoiceDetailUnitPrice = response.records.inventoryPriceDiscount;
            break;
          case 3: this.invoiceDetail.invoiceDetailUnitPrice = response.records.inventorySalePrice;
            break;
          case 4: this.invoiceDetail.invoiceDetailUnitPrice = response.records.inventoryminprice;
            break;
        }

        this.invoiceDetail.invoiceDetailSubtotal = parseFloat((this.invoiceDetail.invoiceDetailQuantity * this.invoiceDetail.invoiceDetailUnitPrice).toFixed(4));
        this.invoiceDetail.invoiceDetailTax = parseFloat((this.invoiceDetail.invoiceDetailSubtotal - (this.invoiceDetail.invoiceDetailSubtotal / this.taxToPay)).toFixed(4));

        await this.invoiceLine.push({
          //Area de Serie
          serial: this.serialSelectedTemp,

          itemCode: this.itemCode,
          measure: "UNI",
          taxCode: 1,
          taxName: "IVA",
          isService: this.invoiceDetail.invoiceDetailType,
          quantity: this.invoiceDetail.invoiceDetailQuantity,
          description: this.invoiceDetail.invoiceDetailDescription,
          discount: 0,
          unitPrice: parseFloat(this.invoiceDetail.invoiceDetailUnitPrice.toFixed(4)),
          price: parseFloat((this.invoiceDetail.invoiceDetailSubtotal).toFixed(4)),
          taxAmount: parseFloat(this.invoiceDetail.invoiceDetailTax.toFixed(4)),
          taxableAmount: parseFloat((this.invoiceDetail.invoiceDetailSubtotal - this.invoiceDetail.invoiceDetailTax).toFixed(4)),
          total: parseFloat(this.invoiceDetail.invoiceDetailSubtotal.toFixed(4)),
        });
        this.invoiceHeader.invoiceTotal += parseFloat(this.invoiceDetail.invoiceDetailSubtotal.toFixed(4));
        this.invoiceHeader.invoiceTax += parseFloat(this.invoiceDetail.invoiceDetailTax.toFixed(4));
        this.cleanLineInvoice();

      } else {
        this.showNotification(2, 'Por favor revisa las cantidad de series seleccionadas');
      }
    } else {
      this.showNotification(3, 'Item ya ingresado, por favor eliminalo antes.');
    }
    this.isDisabled = false;
  }


  // APLICAR DESCUENTO EN TIEMPO REAL
  ApplyDiscount($event, item, descuento) {
    $event.preventDefault();

    this.invoiceLine.forEach((e) => {
      if (e.itemCode == item) {
        let temporal = parseFloat(e.quantity) * parseFloat(e.unitPrice);

        if (parseFloat(descuento) <= temporal) {
          this.invoiceHeader.invoiceTotal = parseFloat((this.invoiceHeader.invoiceTotal - parseFloat(e.total)).toFixed(2));
          this.invoiceHeader.invoiceTax = parseFloat((this.invoiceHeader.invoiceTax - parseFloat(e.taxAmount)).toFixed(2));

          e.price = temporal - parseFloat(descuento);
          e.total = temporal - parseFloat(descuento);
          e.taxAmount = parseFloat((parseFloat(e.price) - (parseFloat(e.price) / this.taxToPay)).toFixed(2));
          e.taxableAmount = parseFloat((parseFloat(e.price) - parseFloat(e.taxAmount)).toFixed(2));

          this.invoiceHeader.invoiceTotal = parseFloat((this.invoiceHeader.invoiceTotal + parseFloat(e.total)).toFixed(2));
          this.invoiceHeader.invoiceTax = parseFloat((this.invoiceHeader.invoiceTax + parseFloat(e.taxAmount)).toFixed(2));
        } else {
          this.showNotification(2, "Cantidad No permitida");
        }
      }
    });
  }

  // ELIMINAR UNA LINEA
  async deleteLine(item) {
    this.isDisabled = true;
    await this.invoiceLine.forEach(async (e, index) => {
      if (e.itemCode === item) {

        if (e.serial) {
          for (const i of e.serial) {
            let indice = this.serialSelected.findIndex(x => x.serialCode === i.serialCode);
            this.serialSelected.splice(indice, 1);
          };
        }

        this.invoiceHeader.invoiceTotal = this.invoiceHeader.invoiceTotal - parseFloat(e.price);
        this.invoiceHeader.invoiceTax = this.invoiceHeader.invoiceTax - parseFloat(e.taxAmount);
        this.invoiceLine.splice(index, 1);
      }
    })
    this.cleanLineInvoice();
    this.isDisabled = false;
  }

  // LIMPIAR LINEA PARA NUEVO INGRESO
  cleanLineInvoice() {
    this.isDisabled = false;
    this.addAmount = true;
    this.priceType = 1;
    this.isEdit = false;
    this.serial = false;
    this.selectedItemsSerial = null;
    this.selectedItems = [];
    this.invoiceDetail = {} as InvoiceDetail;
  }

  async insertDebitNote() {
    if (this.debitNote.debitNoteComment != undefined && this.invoiceLine.length >= 1) {
      // this.loading = true;
      // this.isDisabled = true;

      // Pequeño Contribuyente
      let transmitterType = "NDEB";
      let transmitterMembership = "GEN";
      this.company.companyTaxToPay === 5 ? transmitterMembership = "PEQ" : '';

      this.invoiceHeader.invoiceTax = ((this.invoiceHeader.invoiceTotal / this.taxToPay) * (this.taxToPay - 1));
      
      const hour = await this._utilsService.dateTime('HH:mm:ss');

      const json = {
        "store": {
          "storeCode": this.storeCode
        },
        "user": {
          "userCode": this.userCode
        },
        "transmitter": {
          "transmitterMembership": transmitterMembership, 
          "transmitterType": transmitterType, 
          "transmitterEstablishmentCode": this.store.storeEstablishmentCode,
          "transmitterEmail": this.company.companyEmail,
          "transmitterTaxDocument": this.company.companyTaxDocument,
          "transmitterComercialName": this.store.storeName,
          "transmitterName": this.company.companyName,
          "transmitterAddress": this.store.storeAddress,
          "transmitterZipCode": this.store.storeZipCode,
          "transmitterMunicipality": this.store.storeMunicipality,
          "transmitterDepartment": this.store.storeDepartment,
          "transmitterCountry": 'GT',
          "transmitterPrefix": this.company.companyPrefix,
          "transmitterKey": this.company.companyKey,
          "transmitterToken": this.company.companyToken,
          "transmitterPhraseCode": parseFloat(this.company.companyPhraseCode),
          "transmitterPhraseType": parseFloat(this.company.companyPhraseType)
        },
        "receiver": {
          "receiverAddress": this.client.clientAddress,
          "receiverCode": this.client.clientCode,
          "receiverDeleted": 0,
          "receiverEmail": this.client.clientEmail,
          "receiverName": this.client.clientName,
          "receiverStatus": 1,
          "receiverTaxDocument": this.client.clientTaxDocument,
          "receiverZipCode": 0,
          "receiverCountry": "GT"
        },
        "invoice": {
          "invoiceCode": this.invoiceHeader.invoiceCode
        },
        "debitNote": {
          "debitNoteCode": 0,
          "debitNoteCurrency": "GTQ",
          "debitNoteTaxShortName": "IVA",
          "debitNoteTaxValue": parseFloat((this.invoiceHeader.invoiceTax).toFixed(2)),
          "debitNoteTotal": parseFloat((this.invoiceHeader.invoiceTotal).toFixed(2)),
          "debitNoteComplementId": "Notas",
          "debitNoteComplementName": "Notas",
          "debitNoteSourceDocumentIssuanceDate": this.debitNote.debitNoteDate.substring(0, 10),
       // "creditNoteSourceDocumentIssuanceDate": this.creditNote.creditNoteDate.substring(0, 10),
          "debitNoteFitMotif": this.debitNote.debitNoteComment,
          "debitNoteOrigialDocumentAuthorizationNumber": this.debitNote.debitNoteDocument,
          "debitNoteConcept": this.debitNote.debitNoteConcept,
          "debitNoteDate": this.invoiceHeader.invoiceDate + ' ' + hour,
          "debitNoteInternSerial": this.invoiceHeader.invoiceInternSerial
        },
        "phrase": this.company.phrase,
        "detail": this.invoiceLine,
        "provider": this.company.companyCertifier == 0 ? "infile" : "megaprint",
        "serial": this.serialSelected
      };

      // console.log(json);
      // Insertar Venta
      if (this.company.companyCertifier == 0) {
        this._invoiceService.insertDebitNote(json).then(
          async (response) => {
            if (response.result) {
              let uuid = response.records.infileServiceResponse.registerResponse.uuid;
              window.open('https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=' + uuid);
              // window.open('https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=' + uuid, 'popup', 'width=600,height=600');

              this.cleanModel();
              this.viewBeginning();
              this.showNotification(1, response.message);
            } else {
              this.showNotification(3, response.errorMessage);
            }
          },
          error => {
            this.showNotification(2, error);
            console.log(error);
          }
        ).finally(() => {
          this.loading = false;
          this.isDisabled = false;
        });
      }
      else if (this.company.companyCertifier == 1) {
        this._invoiceService.insertDebitNote(json).then(
          async (response) => {
            if (response.result) {
              console.log(response.records);
              var pdfResult = response.invoicePdf;

              let pdfWindow = window.open("")
              pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(pdfResult) + "'></iframe>")

              this.cleanModel();
              this.viewBeginning();
              this.showNotification(1, response.message);
            } else {
              this.showNotification(3, response.errorMessage);
            }
          },
          error => {
            this.showNotification(2, error);
            console.log(error);
          }
        ).finally(() => {
          this.loading = false;
          this.isDisabled = false;
        });
      }
    } else {
      this.showNotification(2, 'Falto llenar datos!');
    }
  }

  async insertDebitNoteAntiguo() {
    if (this.debitNote.debitNoteComment != undefined && this.invoiceLine.length >= 1) {
      this.loading = true;
      this.isDisabled = true;

      // Pequeño Contribuyente
      let transmitterType = "NDEB";
      let transmitterMembership = "GEN";
      this.company.companyTaxToPay === 5 ? transmitterMembership = "PEQ" : '';

      this.invoiceHeader.invoiceTax = ((this.invoiceHeader.invoiceTotal / this.taxToPay) * (this.taxToPay - 1));

      const json = {
        "store": {
          "storeCode": this.storeCode
        },
        "user": {
          "userCode": this.userCode
        },
        "transmitter": {
          "transmitterMembership": transmitterMembership, 
          "transmitterType": transmitterType, 
          "transmitterEstablishmentCode": this.store.storeEstablishmentCode,
          "transmitterEmail": this.company.companyEmail,
          "transmitterTaxDocument": this.company.companyTaxDocument,
          "transmitterComercialName": this.store.storeName,
          "transmitterName": this.company.companyName,
          "transmitterAddress": this.store.storeAddress,
          "transmitterZipCode": this.store.storeZipCode,
          "transmitterMunicipality": this.store.storeMunicipality,
          "transmitterDepartment": this.store.storeDepartment,
          "transmitterCountry": 'GT',
          "transmitterPrefix": this.company.companyPrefix,
          "transmitterKey": this.company.companyKey,
          "transmitterToken": this.company.companyToken,
          "transmitterPhraseCode": parseFloat(this.company.companyPhraseCode),
          "transmitterPhraseType": parseFloat(this.company.companyPhraseType)
        },
        "receiver": {
          "receiverAddress": this.client.clientAddress,
          "receiverCode": this.client.clientCode,
          "receiverDeleted": 0,
          "receiverEmail": this.client.clientEmail,
          "receiverName": this.client.clientName,
          "receiverStatus": 1,
          "receiverTaxDocument": this.client.clientTaxDocument,
          "receiverZipCode": 0,
          "receiverCountry": "GT"
        },
        "invoice": {
          "invoiceCode": this.invoiceHeader.invoiceCode
        },
        "debitNote": {
          "debitNoteCode": 0,
          "debitNoteCurrency": "GTQ",
          "debitNoteTaxShortName": "IVA",
          "debitNoteTaxValue": parseFloat((this.invoiceHeader.invoiceTax).toFixed(2)),
          "debitNoteTotal": parseFloat((this.invoiceHeader.invoiceTotal).toFixed(2)),
          "debitNoteComplementId": "Notas",
          "debitNoteComplementName": "Notas",
          "debitNoteSourceDocumentIssuanceDate": this.debitNote.debitNoteDate.substring(0, 10),
          "debitNoteFitMotif": this.debitNote.debitNoteComment,
          "debitNoteDate": this.invoiceHeader.invoiceDate,
          "debitNoteOrigialDocumentAuthorizationNumber": this.debitNote.debitNoteDocument
        },
        "detail": this.invoiceLine,
        "provider": this.company.companyCertifier == 0 ? "infile" : "megaprint",
        "serial": this.serialSelected
      };

      // console.log(json);
      // Insertar Venta
      if (this.company.companyCertifier == 0) {
        this._invoiceService.insertDebitNote(json).then(
          async (response) => {
            if (response.result) {
              let uuid = response.records.infileServiceResponse.registerResponse.uuid;
              window.open('https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=' + uuid);
              // window.open('https://report.feel.com.gt/ingfacereport/ingfacereport_documento?uuid=' + uuid, 'popup', 'width=600,height=600');

              this.cleanModel();
              this.viewBeginning();
              this.showNotification(1, response.message);
            } else {
              this.showNotification(3, response.errorMessage);
            }
          },
          error => {
            this.showNotification(2, error);
            console.log(error);
          }
        ).finally(() => {
          this.loading = false;
          this.isDisabled = false;
        });
      }
      else if (this.company.companyCertifier == 1) {
        this._invoiceService.insertDebitNote(json).then(
          async (response) => {
            if (response.result) {
              console.log(response.records);
              var pdfResult = response.invoicePdf;

              let pdfWindow = window.open("")
              pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(pdfResult) + "'></iframe>")

              this.cleanModel();
              this.viewBeginning();
              this.showNotification(1, response.message);
            } else {
              this.showNotification(3, response.errorMessage);
            }
          },
          error => {
            this.showNotification(2, error);
            console.log(error);
          }
        ).finally(() => {
          this.loading = false;
          this.isDisabled = false;
        });
      }
    } else {
      this.showNotification(2, 'Falto llenar datos!');
    }
  }

  // Limpiar Campos
  cleanModel() {
    this.isDisabled = false;
    this.client = {} as Client;
    this.invoiceHeader = {} as InvoiceHeader;
    this.invoiceDetail = {} as InvoiceDetail;
    this.debitNote = {} as DebitNote;
    this.invoiceLine = [];
    this.invoiceHeader.invoiceTotal = 0;
    this.invoiceHeader.invoiceTax = 0;
    this.invoiceHeader.invoiceInternSerial = 'A';

    this.invoiceHeader.invoiceDate = this.nowDay;
  }

  ///////////////////////PANTALLAS///////////////////////
  viewBeginning() {
    this.isBeginning = true;
    this.isDebitNote = false;
  }

  viewDebitNote() {
    this.isBeginning = false;
    this.isDebitNote = true;
  }

  //Mostrar Notificacion
  showNotification(type, message) {
    switch (type) {
      case 1:
        this.toastr.success(
          '<span class="now-ui-icons ui-1_bell-53"></span><b>Exito</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-success alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
      case 4:
        this.toastr.info(
          '<span class="now-ui-icons ui-1_bell-53"></span><b>Info</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-info alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
      case 2:
        this.toastr.warning(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Precaución</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-warning alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
      case 3:
        this.toastr.error(
          '<span class="now-ui-icons ui-1_bell-53"></span> <b>Error</b> - ' + message,
          "",
          {
            timeOut: 5000,
            closeButton: true,
            enableHtml: true,
            toastClass: "alert alert-danger alert-with-icon",
            positionClass: "toast-top-right"
          }
        );
        break;
    }
  }
}
